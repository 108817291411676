<template>
  <div class="jhomeview">
    <div class="jhomeview__jumbotron">
      <div class="jhomeview__jumbotron-content">
        <div class="jhomeview__desc-content">
          <h1 class="jhomeview__title">
            We can help anything what you need
          </h1>
          <p class="jhomeview__desc">
            Founded in 2022, We have been working together with our clients from various industries in aligning their people and processes to compete through high speed, excellent quality and low cost.
          </p>
        </div>
        <img class="jhomeview__jumbotron-img" src="../assets/jumobtron-home-main.png" alt="">
      </div>
    </div>
    <div class="jhomeview__services-container">
      <div class="jhomeview__title-desc-container">
        <h3 class="jhomeview__subtitle jhomeview__subtitle--black">
          We are <span class="jhomeview__subtitle jhomeview__subtitle--blue">committed</span> - to serve you.
        </h3>
        <p class="jhomeview__desc--black">
          Our company is dedicated to providing innovative solutions that not only meet your needs but also empower your success, ensuring you stay ahead in a competitive market with integrity, honesty and openness.
        </p>
      </div>
      <div class="jhomeview__card-container">
        <div 
          v-for="card in cards"
          :key="card"
          class="jcard">
          <div class="jcard__image">
            <img class="jcard__image-asset" :src="card.image" :alt="card.image">
          </div>
          <div class="jcard__content">
            <h4 class="jcard__title">{{ card.title }}</h4>
            <p class="jcard__desc">{{ card.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="jhomeview__collab-container">
      <div class="jhomeview__title-desc-container">
        <h3 class="jhomeview__subtitle jhomeview__subtitle--black">
          <span class="jhomeview__subtitle jhomeview__subtitle--blue">Companies</span> that trust our service.
        </h3>
        <p class="jhomeview__desc--black">
          We are proud to showcase our esteemed partners who have collaborated with us to achieve <br>mutual success and innovation in various industries.
        </p>
      </div>
      <div class="jhomeview__collab-img">
        <div class="jhomeview__collab-list">
          <img v-for="company in companies" :key="company" :src="company.src" :alt="company.src">
        </div>
      </div>
    </div>
    <div class="jhomeview__testimony-container">
      <div class="jhomeview__testimony-content">
        <div class="jhomeview__title-desc-container">
          <h3 class="jhomeview__subtitle">
            What our <span class="jhomeview__subtitle jhomeview__subtitle--black">clients</span> said.
          </h3>
          <p class="jhomeview__desc">
            Our valued partners have shared their positive experiences, highlighting the impactful <br>collaboration and outstanding results achieved through our teamwork.
          </p>
        </div>
        <!-- <div class="jhomeview__carousel">
          <div 
            class="jcard-variant"
            v-for="(testimony, index) in testimonies"
            :key="index"
            :class="{ 'is-active': index === currentTestimonyIndex }"
          >
            <img class="jcard-variant__image" :src="testimony.image" :alt="testimony.name">
            <div class="jcard-variant__content">
              <h2 class="jcard-variant__title">{{ testimony.name }}</h2>
              <p class="jcard-variant__subtitle">{{ testimony.company }}</p>
              <p class="jcard-variant__desc">{{ testimony.message }}</p>
              <div class="jcard-variant__action">
                <div 
                  class="jcard-variant__prev-btn" 
                  @click="prevTestimony"
                >
                  <img src="../assets/icon-chevron.svg" alt="Prev">
                </div>
                <div 
                  class="jcard-variant__next-btn" 
                  @click="nextTestimony"
                >
                  <img src="../assets/icon-chevron.svg" alt="Next">
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>

</template>

<script>
import service1 from '../assets/services-1.svg'
import service2 from '../assets/services-2.svg'
import service3 from '../assets/services-3.svg'
import service4 from '../assets/services-4.svg'
import service5 from '../assets/services-5.svg'
import service6 from '../assets/services-6.svg'
import cpy1 from '../assets/company-logo/cp-1.png'
import cpy2 from '../assets/company-logo/cp-2.webp'
import cpy3 from '../assets/company-logo/cp-3.png'
import cpy4 from '../assets/company-logo/cp-4.png'
import cpy5 from '../assets/company-logo/cp-5.png'
import cpy6 from '../assets/company-logo/cp-6.png'
import cpy7 from '../assets/company-logo/cp-7.png'
import cpy8 from '../assets/company-logo/cp-8.png'
import cpy9 from '../assets/company-logo/cp-9.png'
import testimonyAsset from '../assets/testimony-user.png'
import googleImage from '../assets/icons-google.png'

export default {
  name: 'HomeView',
  data() {
    return {
      cards: [
        {
          image: service1,
          title: "Supplier Logistic",
          desc: "Provides supply chain solutions including procurement, warehousing, inventory management, and timely delivery via various transport modes to optimize client operations."
        },
        {
          image: service2,
          title: "Education & Recruitment",
          desc: "Provides workforce training programs and recruitment services, aligning skilled candidates with client needs to enhance workforce capabilities."
        },
        {
          image: service3,
          title: "Event Organizer & Travel",
          desc: "Plans and manages corporate events, seminars, and travel arrangements, covering everything from venue selection to guest logistics for a seamless experience"
        },
        {
          image: service4,
          title: "Techincal Construction Consultant",
          desc: "Offers consulting for construction projects, including design, planning, project management, and risk assessment, ensuring compliance with safety standards and quality control."
        },
        {
          image: service5,
          title: "Gold & Coal Trading",
          desc: "Facilitates the buying and selling of gold and coal with secure transactions and compliance with legal standards, supported by strong supply chain networks."
        },
        {
          image: service6,
          title: "Export & Import",
          desc: "Manages international shipping with end-toend export & import solutions, including licensing, documentation, packaging, and logistics to ensure efficient delivery."
        }
      ],
      currentTestimonyIndex: 0,
      testimonies: [
        {
          image: testimonyAsset,
          name: 'Rahma Dian Saputri',
          company: 'PT. Niaga Nusantara Sakti',
          message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        },
        // {
        //   image: testimonyAsset,
        //   name: 'John Doe',
        //   company: 'Tech Innovations Inc.',
        //   message: 'It has survived not only five centuries, but also the leap into electronic typesetting.',
        // },
        // {
        //   image: testimonyAsset,
        //   name: 'Jane Smith',
        //   company: 'Creative Solutions',
        //   message: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
        // },
      ],
      companies: [
        { src: cpy9 },
        { src: cpy1 },
        { src: cpy2 },
        { src: cpy4 },
        { src: cpy5 },
        { src: cpy6 },
        { src: cpy7 },
        { src: cpy8 }
      ]
      // collabs: [
      //   {
      //     src: googleImage
      //   }
      // ]
    }
  },
   methods: {
    nextTestimony() {
      this.currentTestimonyIndex =
        (this.currentTestimonyIndex + 1) % this.testimonies.length
    },
    prevTestimony() {
      this.currentTestimonyIndex =
        (this.currentTestimonyIndex - 1 + this.testimonies.length) %
        this.testimonies.length
    }
  }
}
</script>

<style scoped lang="scss">

  .jhomeview {
    &__title {
      margin: 0;
      font-size: 78px;
      font-weight: bold;
      color: #FFFF;
      @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
      }
    }
    &__desc {
      margin: 0;
      color: #FFFF;
      font-size: 24px;
      line-height: 36px;
      &--black {
        color: #1E1E1E;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 20px;
          text-align: center;
          line-height: normal;
        }
      }
      @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        line-height: normal;
      }
    }
    &__jumbotron {
      background: url('../assets/jumbotron-background.png');
      background-size: cover;
    }
    &__jumbotron-content {
      display: flex;
      max-width: 1520px;
      margin: auto;
      gap: 70px;
      align-items: center;
      justify-content: center;
      padding: 100px 24px 245px 24px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 50px 24px 80px 24px;
      }
    }
    &__desc-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &__jumbotron-img { 
      width: 611px; 
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &__services-container {
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 48px;
      @media (max-width: 768px) {
        padding: 50px 24px 80px 24px;
      }
    }
    &__card-container {
      display: grid;
      grid-template-columns: repeat(3, 489.33px);
      grid-auto-rows: 402px;
      gap: 24px;
      justify-content: center;
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        gap: 16px;
      }
      @media (max-width: 480px) {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 16px; /* Consistent gaps */
      }
    }
    &__collab-container {
      padding: 120px 24px;
      margin: auto;
      max-width: 1520px;
      gap: 50px;
      display: flex;
      flex-direction: column;
    }
    &__collab-img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 802px;
      margin: auto;
      gap: 23px;
    }
    &__collab-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* 4 items per row */
      gap: 45px; /* Space between grid items */
      align-items: center;
      justify-items: center; /* Centers items within their grid cell */
    
      @media (max-width: 768px) {
        display: flex; /* Switch to flex layout */
        flex-direction: column; /* Stack items vertically */
        gap: 20px; /* Adjust gap for mobile view */
      }

      img {
        max-width: 254px;
        @media (max-width: 768px) {
          width: 70%; /* Scale down images for mobile */
        }
      }
    }
    &__title-desc-container {
      display: flex;
      gap: 24px;
      flex-direction: column;
      text-align: center;
    }
    &__testimony-container {
      background: url('../assets/background-testimony.png');
      background-size: cover;
    }
    &__testimony-content {
      display: flex;
      flex-direction: column;
      gap: 48px;
      padding: 120px 24px;
      max-width: 1520px;
      margin: auto;
    }
    &__carousel {
       position: relative;
      .jcard-variant {
        display: none;
        transition: opacity 0.3s ease;
        &.is-active {
          display: flex;
          opacity: 1;
        }
      }
    }
    &__subtitle {
      margin: 0;
      font-size: 48px;
      font-weight: bold;
      color: #FFFF;
      &--blue { 
        color: #3397D2; 
      }
      &--black { 
        color: #1E1E1E;
      }
      @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
      }
    }
  }

  .jcard-variant {
    background-color: #FFFF;
    border-radius: 30px 30px 30px 232px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 34px;
    padding: 32px;
    @media (max-width: 768px) {
      flex-direction: column;
      border-radius: 30px;
      padding: 16px;
    }
    &__image {
      width: 430px;
      border-radius: 25px 25px 25px 200px;
      @media (max-width: 768px) {
        width: 200px; 
        border-radius: 25px;
      }
    }
    &__title {
      font-size: 48px;
      font-weight: bold;
      color: #212121;
    }
    &__subtitle {
      font-size: 18px;
      font-weight: 600;
      color: #3397D2;
    }
    &__desc {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 300;
      color: #212121;
    }
    &__action {
      margin-top: 24px;
      display: flex;
      gap: 24px;
    }
    &__prev-btn,
    &__next-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #3397D2;
      cursor: pointer;
    }
    &__next-btn {
      transform: rotate(180deg);
    }
  }

  .jcard {
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding: 24px 24px 90px 24px;
    align-items: start;
    justify-content: start;
    cursor: pointer;
    transition: .2s all ease-in-out;
    &:hover {
      background-color: #3397D2;
      color: #FFFF;
      .jcard {
        &__title,
        &__desc {
          color: #FFFF;
          fill: #FFFF;
        }
        &__image-asset {
          filter: brightness(3) grayscale(1);
        }
      }
    }
    &__title {
      color: #1E1E1E;
      font-size: 32px;
      line-height: 36px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    &__desc {
      margin-top: 12px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #757575;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

</style>
